/*!
 * Dashboard UI Kit - v1.3
 * Copyright 2017 ZawiaStudio
 * @zawiastudio | zawiastudio.com 
 */

//
// Table of content:
// 
// Tools........... Dashboard's scss mixins & functions
// Settings........ Dashboard's scss variables
// Base............ Resets, bare elements styling
// Vendors......... Exteneral assests e.g. bootstrap-grid
// Objects......... Layout-specific 
// Components...... UI-Kit, admin dashboard components
// Utilities....... Helper classes e.g. display utilities

/**
 * Table of content: 
 *
 * Base/
 * Base Global
 * Base Typography
 *
 * Vendors/
 * Bootstrap Grid
 * Font Awesome
 * Fullcalendar
 * Fullcalendar Custom
 * Dragula
 *
 * Components/
 * Buttons
 * Button Groups
 * Navbar
 * Nav
 * Tables
 * Pagination
 * Switches
 * Badges
 * Select
 * Tooltips
 * Alerts
 * Avatars
 * Progress
 * Tabs
 * Calendar
 * Dropdown
 * Breadcrumb
 * Notifications
 * Toolbar
 * Toggle
 * Choice
 * Field
 * Switch
 * Inputs
 * Ranges
 * Close
 * Modal
 * Profile-card
 * Event
 * Sidebar
 * Projects
 * Post
 * Stream
 * Gallery-card
 * State
 * Landing-card
 * Todo
 * Messanger
 * Credit-card
 * Search-result
 * Search-form
 * Card
 * Graph-card
 * Divider
 * Progress-card
 * Panel
 * Progress-circle
 * State-card
 * Summary
 * Chart
 * Login
 * Project-card
 * Menu
 * Boards
 * Onboard
 * Plans
 * Fileitem
 * Overview-card
 * invoice
 * error
 * feed
 * rating
 * map
 * candidate
 * stage
 * counter-nav
 * chat-dialogue
 *
 * Utilities/
 * Typography
 * Floats
 * Clearfix
 * Spacing
 * Colors
 * Display
 * Borders
 * Flex
 * Responsive
 * Opacity
 * Sizes
 */

//
// TOOLS
//
@import "tools/tools.rem";
@import "tools/tools.clearfix";
@import "tools/tools.linear-gradient";
@import "tools/tools.respond-to";


//
// SETTINGS 
// 

// Global Settings
@import "settings/settings.global";

// UI-Kit Settings
@import "settings/settings.buttons";
@import "settings/settings.tables";
@import "settings/settings.navbar";
@import "settings/settings.nav";
@import "settings/settings.toolbar";
@import "settings/settings.badges";
@import "settings/settings.alerts";
@import "settings/settings.pagination";
@import "settings/settings.avatars";
@import "settings/settings.progress";
@import "settings/settings.tabs";
@import "settings/settings.calendar";
@import "settings/settings.dropdown";
@import "settings/settings.breadcrumb";
@import "settings/settings.notifications";
@import "settings/settings.tooltips";
@import "settings/settings.inputs";
@import "settings/settings.toggle";
@import "settings/settings.select";
@import "settings/settings.choice";
@import "settings/settings.field";
@import "settings/settings.switches";
@import "settings/settings.divider";
@import "settings/settings.close";
@import "settings/settings.scrollbar";
@import "settings/settings.range";
@import "settings/settings.popovers";

@import "tools/tools.scrollbar";


//
// BASE
//
@import "base/base.global";
@import "base/base.typography";


//
// VENDORS
//
@import "vendor/bootstrap-grid";
@import "vendor/fontawesome";
@import "vendor/fullcalendar";
@import "vendor/fullcalendar-custom"; // override `fullcalendar` default styles
@import "vendor/dragula";
@import "vendor/select2";
@import "vendor/select2-custom"; // override `select2` default styles
@import "vendor/dropzone";
@import "vendor/dropzone-custom"; // override `dropzone` default styles
@import "vendor/datatables-custom"; // override `datatables` default styles
@import "vendor/datepicker";
@import "vendor/datepicker-custom"; // override `datepicker` default styles
@import "vendor/jqvmap";
@import "vendor/jqvmap-custom"; // override `jqvmap` default styles

//
// OBJECTS
//
@import "objects/objects.page";
@import "objects/objects.media";


//
// COMPONENTS
//

// UI-Kit Components
@import "components/components.buttons";
@import "components/components.button-group";
@import "components/components.navbar";
@import "components/components.nav";
@import "components/components.tables";
@import "components/components.pagination";
@import "components/components.switch";
@import "components/components.badges";
@import "components/components.tooltips";
@import "components/components.alerts";
@import "components/components.avatars";
@import "components/components.progress";
@import "components/components.tabs";
@import "components/components.dropdown";
@import "components/components.breadcrumb";
@import "components/components.notifications";
@import "components/components.toolbar";
@import "components/components.toggle";
@import "components/components.choice";
@import "components/components.field";
@import "components/components.switch";
@import "components/components.inputs";
@import "components/components.ranges";
@import "components/components.close";
@import "components/components.modal";
@import "components/components.popover";


// Dashboard/Admin-Related Components
@import "components/components.profile-card";
@import "components/components.event";
@import "components/components.sidebar";
@import "components/components.projects";
@import "components/components.post";
@import "components/components.stream";
@import "components/components.gallery-card";
@import "components/components.state";
@import "components/components.landing-card";
@import "components/components.todo";
@import "components/components.messanger";
@import "components/components.credit-card";
@import "components/components.search-result";
@import "components/components.search-form";
@import "components/components.card";
@import "components/components.graph-card";
@import "components/components.divider";
@import "components/components.progress-card";
@import "components/components.panel";
@import "components/components.progress-circle";
@import "components/components.state-card";
@import "components/components.summary";
@import "components/components.chart";
@import "components/components.login";
@import "components/components.project-card";
@import "components/components.menu";
@import "components/components.boards";
@import "components/components.onboard";
@import "components/components.plans";
@import "components/components.fileitem";
@import "components/components.overview-card";
@import "components/components.invoice";
@import "components/components.error";
@import "components/components.feed";
@import "components/components.rating";
@import "components/components.map";
@import "components/components.candidate";
@import "components/components.stage";
@import "components/components.counter-nav";
@import "components/components.chat-dialogue";

// UTILITIES
@import "utilities/utilities.typography";
@import "utilities/utilities.floats";
@import "utilities/utilities.clearfix";
@import "utilities/utilities.spacing";
@import "utilities/utilities.colors";
@import "utilities/utilities.display";
@import "utilities/utilities.borders";
@import "utilities/utilities.flex";
@import "utilities/utilities.responsive";
@import "utilities/utilities.opacity";
@import "utilities/utilities.sizes";

