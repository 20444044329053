/* ==========================================================================
   #STAGE
   ========================================================================== */

/**
 * Stage component is used to describe the project you are working on based on
 * multiple stages. This component is used in project-overview.html page.
 */

.c-stage {
	margin-bottom: $spacer;
	border: $base-border-width solid $base-border-color;
	border-radius: $base-border-radius;
	background-color: $base-background-color;
}

.c-stage__header {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: $spacer-small $spacer;
	border-bottom: $base-border-width solid $base-border-color;
	cursor: pointer;

	&:hover {
		text-decoration: none;
	}
}

.c-stage__header-img {
	max-width: 60px;
	margin-right: 20px;
	border-radius: $base-border-radius;
	overflow: hidden;
}

.c-stage__icon {
	width: 30px;
	height: 30px;
	margin-right: $spacer-xsmall;
	border: $base-border-color solid $base-border-width;
	border-radius: 100%;
	color: $color-mute;
	font-size: 14px;
	line-height: 30px;
	text-align: center;
}

.c-stage__panel {
	border-bottom: $base-border-width solid $base-border-color;

	/**
	 * Bootstrap classes used to show and hide sidebar submenu.
	 * This behaviour depends on collapse.js plugin.
	 */
	&.collapse {
		display: none;

		&.show {
			display: block;
	  	}
	}

	&.collapsing {
		position: relative;
		height: 0;
		transition: height 0.3s;
		overflow: hidden;
	}

	&--mute {
		background-color: #FAFBFC;
	}
}

.c-stage__label {
	display: flex;
	align-items: center;
	padding: $spacer-small $spacer;
	background-color: $color-success;
	color: $white;
}

.c-stage__label-icon {
	margin-right: $spacer-xsmall;
	font-size: $h2-font-size;
}

.c-stage__label-title {
	color: $white;
}


